.socialres-container{
    display: none;
}

.socialres-box{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    flex-direction: column;
    
}


.socialres-box img{
    transition: 0.5s;
        margin-top: 3rem;
        width: 100%;
        max-width: 200px;

}
.socialres-box > h1{
    text-align: center;
     color: white;
     font-family:Arial, Helvetica, sans-serif;
     margin-top: 1rem;
     font-size: 25px;
     letter-spacing: 0.1rem;
     text-transform: uppercase;
     font-weight: 200;
}
.socialres-box > h1 > span{
     color: rgb(81, 189, 247);
}


.socialres-data-box{
    width: 100%;
    max-width: 600px;
    height: 100%;
    display: flex;
    justify-content: space-around;
    padding: 2rem;
    margin-top: -1.6rem;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1rem;
}
.socialres-data-box img{ 
     width: 100%;
     max-width: 55px;
}
.socialres-data-box  #x{
    padding: 8px;
}
.socialres-data-box img:hover{ 
     transition: 0.3s;
     transform: scale(120%);
}

@media only screen and (max-width: 1010px) {
    .socialres-container{
     display: block;
    }
 }