.fullBlogContainer {
    max-width: 950px;
    margin: 40px auto;
    padding: 20px;
    background-color: #212121; 
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); 
    margin-bottom: 5rem;
    margin-top:4rem;
    animation: slideIn 0.5s forwards;
    opacity: 1;
    animation-delay: 0.2s;
  }


  @keyframes slideIn {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}
  
  .blog-profile {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .blog-profile img {
    object-fit: contain;
    border-radius: 50%;
    margin-right: 10px;
  }
  
  .blog-profile h3 {
    font-weight: bold;
    margin-right: 10px;
    color: #fff; /* white text color */
  }
  
  .blog-profile h4 {
    font-weight: normal;
    color: #aaa; /* light gray text color */
  }
  
  .heading {
    margin-bottom: 20px;
  }
  
  .heading h1 {
    font-size: 24px;
    font-weight: bold;
    color: #fff; /* white text color */
  }
  
  .main-blog {
    margin-bottom: 20px;
  }
  
  .main-blog img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* dark shadow */
  }
  
  .main-blog p {
    font-size: 16px;
    color: #aaa; /* light gray text color */
    margin-bottom: 20px;
  }
  
  .secondary-image {
    width: 50%;
    height: 200px;
    object-fit: cover;
    border-radius: 10px;
    margin-bottom: 20px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3); /* dark shadow */
  }
  
  .tags {
    margin-bottom: 20px;
  }
  
  .tags h3 {
    font-weight: bold;
    margin-bottom: 10px;
    color: #fff; /* white text color */
  }
  
  .tags p {
    font-size: 14px;
    color: #aaa; /* light gray text color */
  }
  
  .related-articles {
    margin-bottom: 20px;
  }
  
  .related-articles h3 {
    font-weight: bold;
    margin-bottom: 10px;
    color: #fff; /* white text color */
   
  }
  
  .related-articles ul {
      list-style: none;
      padding: 0;
      margin: 0;
    
}
  .related-articles li {
    margin-bottom: 10px;


  }
  
  .related-articles a {
    font-size: 14px;
    text-decoration: none;
    padding-bottom: 2px; 
    border-bottom: 1px solid;
    transition: border-color 0.2s ease; 
    color: rgb(122, 122, 215);
}
.related-articles a:hover{
    color: rgb(11, 11, 177);
  }
  
  .social-media-links {
    margin-bottom: 20px;
  }
  
  .social-media-links h3 {
    font-weight: bold;
    margin-bottom: 10px;
    color: #fff; /* white text color */
  }
  
  .social-media-links ul {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 1rem;
}

.social-media-links li {
    margin-right: 10px;
  }
  
  .social-media-links a {
    font-size: 25px;
    color: #aaa; /* light gray text color */
    text-decoration: none;
  }