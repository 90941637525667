@import url('https://fonts.googleapis.com/css2?family=Roboto+Condensed&display=swap');


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}


body {
    overflow-y: scroll;
    background-color: black;
}
body::-webkit-scrollbar {
    width:10px;
    background-color: black;
}
body::-webkit-scrollbar-track {
    -webkit-box-shadow:inset 0 0 10px rgba(40, 125, 222, 0.595); 
    border-radius:5px;
}
body::-webkit-scrollbar-thumb {
    border-radius:5px;
    -webkit-box-shadow: inset 0 0 30px blue; 
}

hr{
    background-color: blue;
    color: blue;
    border: none;
    box-shadow: 0px 0px 50px 10px blue;
}

.home-box1{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}


.home-box1 ul{
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    margin-top: 1rem;
    list-style: none;
    justify-content: space-evenly;
}

.home-box1 ul li{
    padding: 0.6rem;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    font-family: 'Roboto Condensed', sans-serif;
    color: rgb(231, 220, 244);


}

.home-box1 ul li img{
    margin: 0rem 0.3rem;
    max-width: 35px;
    
}
.home-box1 ul li span{
    display: flex;
    justify-content: center;
    text-decoration: none;
    align-items:flex-end;
    font-size: 1.2rem;
}
.home-box1 ul li:hover{
    cursor: pointer;
    color: rgb(77, 95, 111);   
}

.home-box1 button{
    border: none;
    background-color: transparent;
    color: rgb(231, 220, 244);
    font-size: 1.5rem;
    letter-spacing: 0.2rem;
    text-transform: capitalize;
    font-weight: bold;
}
.home-box1 button:hover{
    cursor: pointer;
    color: rgb(173, 167, 179);
    transition: 0.1s;
}

.home-box2{
        width: 100%;
        height: 100%;
        display: flex;
        padding: 2rem;
}


    
.home-inner-box1{
    flex: 50%;
    display: flex;
    justify-content: center;
    color: aliceblue;
    align-items: center;
    margin-bottom: auto;
    mix-blend-mode: difference;
    margin-top: 2rem;
}



.intro-box h1{
    font-size: 4rem;
    max-width: 40rem;
    padding: 1rem;
    color: rgb(231, 220, 244);
}
.intro-box h1 span{
   color: rgb(96, 96, 241);
}
.intro-box p{
    font-size: 1.4rem;
    line-height: 2.2rem;
    max-width: 35rem;
    padding: 1rem;
}
.intro-box button{
    margin: 1rem;
    padding: 1rem 3rem;
    border: none;
    font-family:'Roboto Condensed', sans-serif;
    font-size: 1.5rem;
    letter-spacing: 0.1rem;
}

.intro-box .hireMe{
   background-color: blue;
   color: white;
   border: 2px solid blue;
}

.intro-box .knowMore{
   background-color: transparent;
   border: 2px solid blue;
   color: white;

}
.intro-box .hireMe:hover{
   background-color:transparent;
   color: white;
   transition: 0.1s;
   cursor: pointer;
}

.intro-box .knowMore:hover{
   background-color: blue;
   color: white;
   transition: 0.1s;
   cursor: pointer;
}

.home-inner-box2{
    width: 100%;
    height: 100%;
    flex: 30%;
    display: flex;
    justify-content: center;
    align-items: center;
}
.home-inner-box2 img{
    width: 100%;
    max-width: 800px;
    min-width: 600px;
    mix-blend-mode: difference;
}

@media only screen and (max-width: 900px) {

    .home-box1 ul{
        flex-direction: column;
        gap: 1rem;
    }
    

    .home-inner-box2{
        display: none;
    }
  }

@media only screen and (max-width: 1010px) {

    .home-box1{
        display: none;
    }
    .home-inner-box2{
        margin-top: 6rem;
     }
     
}

@media only screen and (max-width: 1260px) {

    .intro-box{
            margin-left: 2rem;
    }
    
}


@media only screen and (max-width: 950px) {

}