*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;  
}

.undercons-container {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2rem;
    margin-bottom: 2rem;
}
.undercons-container img{
    width: 100%;
    max-width: 700px;
}
.undercons-container h1{
    width: 100%;
    text-transform: uppercase;
    text-align: center;
    font-family: Arial, Helvetica, sans-serif;
    line-height: 3rem;
    color: rgb(96, 158, 213);
}
.undercons-container h2{
    color: rgb(87, 103, 118);
     text-align: center;
     font-family: Arial, Helvetica, sans-serif;
}
.undercons-container h3{
    cursor: pointer;
    text-decoration: underline;
}

@media only screen and (max-width: 950px) {
    .undercons-container img{
        margin-top: 5rem;
       
    }
   
   
}