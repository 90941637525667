@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    background-color: #121212;
    font-family: 'Poppins', sans-serif;
}

/* Container for Project Boxes */
.project-box1 {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-bottom: 5rem;
    padding: 0 2rem;
}

.project-box1 > h1 {
    text-align: center;
    color: white;
    font-family: 'Poppins', sans-serif;
    letter-spacing: 0.05rem;
    margin-top: 3rem;
    font-size: 3rem;
}

.project-box1 > h1 span {
    color: #3498db;
}

.project-box1 > p {
    color: white;
    text-align: center;
    font-size: 1.4rem;
    margin-bottom: 2rem;
    font-family: 'Courier New', Courier, monospace;
    max-width: 600px;
    line-height: 1.6;
}

/* Inner Project Boxes */
.project-inner-box1 {
    display: flex;
    justify-content: center;
    align-items: stretch; /* This ensures that all child items (boxes) stretch to the same height */
    flex-wrap: wrap;
    gap: 2rem;
    padding: 0 1rem;
}

.project-data-box {
    font-family: 'Poppins', sans-serif;
    width: 24rem;
    min-height: 400px; 
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between; 
    align-items: center;
    padding: 1rem;
    border: 2px solid blue;
    transition: transform 0.4s ease, background-color 0.4s ease;
}

.project-data-box:hover {
    transform: scale(1.05);
}

.project-data-box img {
    width: 100%;
    height: auto;
    border-radius: 10px;
    transition: transform 0.5s ease;
}

.project-data-box img:hover {
    transform: scale(1.1);
}

.project-data-box h1 {
    font-size: 1.5rem;
    letter-spacing: 0.1rem;
    color: rgb(245, 241, 242);
    text-align: center;
}

.project-data-box p {
    line-height: 1.5;
    text-align: left;
    color: white;
    padding: 0rem 0rem 1rem 0rem;
    font-size: 1rem;
}

.project-data-box p .projectView {
    all: unset;
    color: white;
    cursor: pointer;
    font-weight: bold;
}

.project-data-box p .projectView:hover {
    text-decoration: underline;
}

.project-data-box .link-section{
    display: flex;
    justify-content: space-around;
    width: 100%;
}

.project-data-box .project-link {
    text-decoration: none;
    background-color: blue;
    padding: 0.7rem 1rem;
    color: white;
    border-radius: 5px;
    transition: background-color 0.3s ease;
}

.project-data-box .project-link:hover {
    background-color: white;
    color: #3498db;
}

.project-data-box > h3 {
    text-transform: uppercase;
    color: white;
    padding: 0.3rem 0rem 0rem 0rem;
}

.project-data-box > h3 > span {
    color: #3498db;
}

/* Media Queries for Responsive Design */
@media only screen and (max-width: 950px) {
    .project-box1 > h1 {
        margin-top: 5rem;
        font-size: 2.5rem;
    }

    .project-box1 > p {
        font-size: 1.2rem;
    }

    .project-data-box {
        width: 20rem;
    }
}

@media only screen and (max-width: 600px) {
    .project-box1 > h1 {
        font-size: 2rem;
    }

    .project-box1 > p {
        font-size: 1.1rem;
    }

    .project-data-box {
        width: 100%;
        padding: 1rem;
    }

    .project-inner-box1 {
        gap: 1rem;
    }
}
