@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap");

.blog-main-container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    margin-bottom: 5rem;
    margin-top: 1rem;
    padding: 2rem;
    gap: 2rem;
    box-sizing: border-box;
}

.blog-main-container h1 {
    color: white;
}

.blog-main-container .blog-main-box {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 5rem;
    flex-wrap: wrap;
}

.blog-inner-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
    gap: 2rem;
    padding: 2rem;
    width: 400px;
    box-shadow: 5px 5px 50px 5px blue;
    animation: slideInLeft 0.5s ease-out; /* Animation added */
    transition: 0.5s;
}

.blog-inner-container:hover{
    box-shadow: none;
    transform: scale(110%);
    background-color: rgb(96, 51, 162, 0.01);
}

@keyframes slideInLeft {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.blog-inner-container img {
    width: 50%;
}

.blog-inner-container button {
    width: 100%;
    padding: 1rem;
    font-size: 1.1rem;
    border-radius: 10px;
    background-color: blue;
    color: white;
    outline: none;
    cursor: pointer;
    border: none;
}

.blog-inner-container button:hover {
    background-color: white;
    color: blue;
}


.blog-inner-container button {
  width: 100%;
  padding: 1rem;
  font-size: 1.1rem;
  border-radius: 10px;
  background-color: blue;
  color: white;
  outline: none;
  cursor: pointer;
  border: none;

}
.blog-inner-container button:hover {
 background-color: white;
 color: blue;

}


