

@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap");

/* Define the animation */
.CatBlog-container {
    display: flex;
    padding: 3rem;
    flex-direction: column;
    gap: 1.5rem;
}

.blog-animation {
    animation: slideIn 0.5s forwards;
    opacity: 1;
    animation-delay: 0.2s;
}

@keyframes slideIn {
    0% {
        transform: translateX(-100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.animate-in {
    opacity: 1;
}

