nav {
    display: none;
    position: fixed;
    top: 0;
    width: 100%;
    height: 50px;
    box-sizing: border-box;
    background-color: black;

}

.nav-logo{
    width: 70px;
    border-radius: 50%;
    mix-blend-mode:difference;
}

.nav-res-logo{
    border-radius: 50%;
    width: 40px;
    border: 3px solid rgb(32, 32, 111);
    z-index: 1;
}


nav  h3{
        margin: 0;
        padding: 0;
}


.list{
    position: relative;
    visibility: hidden;
    left: -100%;
    width: 25rem;
    top: -10px;
    height: 100vh;
    overflow: scroll;
    transition: 1s;
    color: #f1f1f1;
    background-color: rgb(0, 0, 0, 0.8);
}

.list img{
    width: 80%;
    margin-top: 2rem;
    margin-bottom: 4rem;
}

.items {
    display:flex;
    justify-content: center;
    gap: 1rem;
    font-size: 20px;
    text-transform: uppercase;
    color: rgb(93, 93, 220);
    font-weight: 600;
    cursor: pointer;
}

.items i{
    color: #f1f1f1;
}


@media screen and (max-width: 1010px){
    .items {
        margin-left: 2.5rem;
        margin-top: 50px;
        width: 100%;
        text-align: center;
        margin-right: 0px;
        padding: 10px 0;
    }
   

}


@media only screen and (max-width: 1010px) {
    nav{
        display: block;
        z-index: 1;
    }
   
}







#label-toggle *{
    position: absolute;
    top: 20px;
    right: 20px;
    color: rgb(51, 51, 191);
    transform: scale(180%);
}


input[type=checkbox] {
    display: none;
  } 
input[type=checkbox]:checked ~ .list{
    transition: 0.5s ease-out;
    display: block;
    visibility: visible;
    left: -10%;
} 

@media screen and (max-width: 650px){

    input[type=checkbox]:checked ~ .list{
        left: -20%;
    } 
    nav > h3{
        top: 20px;
        font-size: 20px;
    }
   
}
@media screen and (max-width: 400px){

    input[type=checkbox]:checked ~ .list{
        left: -20%;
    } 
   
}
@media screen and (max-width: 350px){

    input[type=checkbox]:checked ~ .list{
        left: -20%;
    } 
    nav > h3{
        top: 25px;
        left: 45px;
    }

}