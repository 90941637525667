
@import url('https://fonts.googleapis.com/css2?family=Combo&family=Poppins&family=Roboto+Condensed:wght@300&family=Rubik&display=swap');


.about-box1{
    width: 100%;
    height: 100%;
    position: relative;
    margin-bottom: 5rem;
    top: 5rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.about-inner-box1{
    padding: 2rem;
    width: 100%;
    max-width: 750px;
    margin-bottom: auto;
    text-align: center;
}

.whoiam-box{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.whoiam-box img{
    display: none;
    width: 100%;
    max-width: 250px;
    margin-top: -6rem;    
}

.whoiam-box > h1 > span{
    color: blue;
}

.whoiam-box > h1{
    font-family: 'Combo', cursive;
    font-size: 80px;
    color: white;
}


.about-inner-box1 > h3{
    color: white;
    font-weight: 200;
    font-family: 'Rubik', sans-serif;
    font-size: 20px;
    letter-spacing: 0.1rem;
}
.about-inner-box1 > h3 >span{
    font-size: 25px;
    color: blue;
}

.about-inner-box2 > img {
    width: 100%;
    max-width: 500px;
}

@media only screen and (max-width: 720px) {
    .about-inner-box2 > img {
        display: none;
    }
    .about-inner-box1 > h3{
        text-align: left;
    }
 }

 @media only screen and (max-width: 950px) {
    .about-box1{
        z-index: -1;
    }
 }