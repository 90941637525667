@import url('https://fonts.googleapis.com/css2?family=Signika+Negative&display=swap');

*{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

.error-container{
    width: 100%;
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.error-container img{
     width: 39%;
     margin-top: 3rem;
     max-width: 800px;
}
.error-container h1{
     text-align: center;
     line-height: 3rem;
     font-size: 1.6em;
     color: rgb(48, 48, 197);
     font-family: 'Signika Negative', sans-serif;
     letter-spacing: 0.1rem;

}


.noblog{
    display: flex;
    height: 60vh;
    justify-content: center;
    align-items: center;
}


