.blogComp-container {
    display: flex;
    background-color: #1a1a1a;
    color: #ffffff;
    padding: 20px;
    margin-bottom: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.first {
    flex: 1;
    max-width: 200px;
    margin-right: 20px;
}

.first img {
    width: 100%;
    border-radius: 8px;
}

.second {
    flex: 2;
    display: flex;
    flex-direction: column;
}

.blog-profile {
    display: flex;
    gap: 0.2rem;
    font-family: "Roboto", sans-serif;
}

.blog-profile img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    margin-right: 10px;
}

.title {
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.viewMore_blog {
    margin-top: 10px;
    text-decoration: none;
    font-size: 0.6rem;
    padding: 0.5rem;
    color: #ffffff;
    border-radius: 10px;
    display: flex;
    background-color: blue;
    width: 200px;
    margin-bottom: 0.5rem;
    align-items: center;
    justify-content: center;
}

.viewMore_blog:hover {
    color: blue;
    background-color: white;
}

h2, h3, h4 {
    font-size: 16px;
    line-height: 1.6;
    margin-bottom: 10px;
}

i {
    color: #ffa500;
}

/* Responsive design */
@media (max-width: 768px) {
    .blogComp-container {
        flex-direction: column;
        align-items: center;
        text-align: center;
    }

    .first {
        margin-right: 0;
        margin-bottom: 20px;
        max-width: 100%;
    }

    .blog-profile {
        flex-wrap: wrap;
        justify-content: center;
    }

    .blog-profile h3,
    .blog-profile h4 {
        font-size: 14px;
        margin: 5px;
    }

    .second {
        width: 100%;
    }
}
