.social-container{
        width: 100%;
        height: 100%;
}

.social-box{
    display: flex;
    height: 80%;
    flex-direction: column;
    position: fixed;
    justify-content: center;
    gap: 1rem;
    padding: 1rem;
    z-index: 1;
}

.social-box img{
    width: 100%;
    max-width: 45px;
}
.social-box #x{
    padding: 0.4rem;
}

.social-box img:hover{
    cursor: pointer;
    transition: 0.3s;
    transform: translateX(30%);
}

.social-box .arrow-icon{
    align-self: center;
    width: 30px;
}
.social-box .arrow-icon:hover{
    align-self: center;
    width: 30px;
    transition: none;
    transform: none;
}



@media only screen and (max-width: 1010px) {

   .social-box{
    display: none;
   }
}