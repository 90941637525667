@import url('https://fonts.googleapis.com/css2?family=Signika+Negative&display=swap');

.skills-box1{
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            
            
}
.skills-box1 > h1{
    margin-top: 2rem;
    /* margin-bottom: 1rem; */
    font-size: 4rem;
    text-align: center;
    letter-spacing: 0.5rem;
    color: white;
    font-family: 'Courier New', Courier, monospace;
    
            
}
.skills-box1 > h1 > span{
    color: blue;
    font-family: 'Courier New', Courier, monospace;

            
}
.skills-box1 > p{
       text-transform: uppercase;
        color: white;
        font-size: 1.3rem;
        margin-bottom: 3rem;
        letter-spacing: 0.2rem;
        max-width: 30rem;
        line-height: 2rem;
        text-align: center;
        color: rgb(190, 172, 172);

}
.skills-box1 > p > span{
      color: blue;
      font-size: 30px;
      font-weight: bold;
}


.skills-inner-box1{
    display: flex;
    justify-content: space-evenly;
    margin: 5rem;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    margin-top: 2rem;

   

}

.skills-main-boxes{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 5px 5px 45px 0px rgb(3, 3, 172);
    max-width: 20rem;
    margin: 2rem;
    width: 100%;
    width: 18rem;
    font-size: 20px;
    color: white;
    height: 15rem;
    margin-bottom: 10vh;
    border-radius: 2rem;
    transition: 1s;
}
.skills-main-boxes:hover{
    box-shadow: 0px 0px 0px 0px black;
    transition:  0.2s;
    transition-timing-function: linear;
    cursor: pointer;
    transform: scale(120%);
}
.skills-main-boxes > img{
   width: 100%;
   max-width: 60px;
}




.skills-inner-box1 > h1{
        color: white;
        font-family: 'Signika Negative', sans-serif;
        font-size: 60px;
        letter-spacing: 0.5rem;
}

.skills-inner-box1 > h1 > span{
        color: blue;
}

.gears-container{
    width: 100%;
    height: 100%;
    display: flex;
    margin-top: 3rem;
    justify-content: center;
    align-items: center;
}

.gearsBox{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 2rem;
    padding: 2rem;
}

.gearsBox img{
    box-shadow:inset 2px 2px 112px 2px rgb(44, 13, 178);
    padding: 2rem;
    width: 100%;
    max-width: 150px;
    border-radius: 50%;
    height: 100%;
    transition: 1s;
}

.gearsBox img:hover{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 2rem;
    transform: scale(150%);
    transition: 0.3s;
    box-shadow: 2px 2px 2px 2px black
    ,inset 2px 2px 2px 2px ;
    cursor: pointer;
   
}

@media only screen and (max-width: 950px) {
    .skills-box1 > h1{
         margin-top: 5rem;
    }

 }