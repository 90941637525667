
.backvideo-box > video{
    position: fixed;
    z-index: -1;
    width: 100%;
    height: auto;   
}

@media (min-aspect-ratio: 16/9){
    .backvideo-box > video{
                width: 100%;
                height: auto;
    }
}

@media (max-aspect-ratio: 16/9){
    .backvideo-box > video{
                width: auto;
                height: 100%;
    }
}