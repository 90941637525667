/* Global Styles */

* {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
  }
  

  .blogs-box1 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 50px;
    margin-bottom: 5rem;
  }
  
  .blogs-inner-box1 {
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
    padding: 2rem;
    color: rgb(22, 22, 162);
    display: flex;
    flex-direction: column;
    gap: 2rem;
    background-color: rgba(217, 212, 228, 0.1);
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(24, 89, 187, 0.5);
  }
  
  .blogs-box-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .link-styele {
    text-decoration: none;
    color: #883939;
    transition: all 0.3s ease-in-out;
  }
  
  .link-styele:hover {
    color: #ccc;
  }
  
  /* Card Styles */
  
  .card {
    margin: 20px;
    display: flex;
    gap: 1rem;
    justify-content: space-around;
    align-items: center;
    background-color: rgb(71, 5, 109, 0.2);
    border-radius: 10px;
    padding: 1rem;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease-in-out;
  }
  
  .card:hover {
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
  }
  
  .card img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  
  .card h1 {
    font-family: 'Montserrat', sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    text-align: center;
    color: #fff;
    letter-spacing: 1px;
  }
  
  .card h1 span {
    font-size: 18px;
    font-weight: 400;
    color: #ccc;
  }
  
