.projectfull-details {
    max-width: 1200px;
    margin: 2rem auto;
    padding: 2rem;
    background-color: #1e1e1e;
    border-radius: 12px;
    box-shadow: 0 4px 15px rgba(0, 0, 0, 0.5); 
    transition: all 0.3s ease;
    margin-bottom: 10rem;
}

.projectfull-details:hover {
    transform: scale(1.02); 
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.6);
}



.projectfull-details img {
    width: 100%;
    height: auto;
    margin-bottom: 1.5rem;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.6); 
}



