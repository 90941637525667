.resumeArea{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 3rem;
    gap: 1rem;
    padding: 2rem;
    padding-top: 3rem;
}
.resumeArea img{
    box-sizing: border-box;


}

.resumeArea button{
    width: 30%;
    padding: 1rem;
    border: none;
    outline: none;
    font-size: inherit;
    border-radius: 15px;
    font-weight: bolder;
    color: rgb(226, 232, 215);
    cursor: pointer;
    transition: 0.5s;
    background: linear-gradient(108deg,blue, rgb(167, 142, 142), green);
}
.resumeArea button:active{
    transform: scale(0.7);
    transition: 0.1s;
    background: linear-gradient(108deg,green, rgb(167, 142, 142), blue);

}

@media only screen and (max-width: 950px) {
     .resumeArea{
            position: relative;
            top:2rem
     }

    .resumeArea img{
        box-sizing: border-box;
        width: 100%;
    }
   
}