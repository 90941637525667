
.footer-box{
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-wrap: wrap;
    position: fixed;
    bottom: 0;
    width: 100%;
    background-color: rgb(2, 7, 50);
    padding: 1rem;
}

.footer-box > h1{
    text-align: center;
    letter-spacing: 0.1rem;
    color: White;
    font-family: 'Courier New', Courier, monospace;
    font-size: 20px;

}
.footer-box > h1 > span{
            color: blue;
}
@media only screen and (max-width: 950px) {
    .footer-box{
        margin-top: auto;
    }
    .footer-box h1{
        font-size: 10px;
    }

   
}